/**
 * Lightweight checker that loads fast so we can redirect users
 * to a page that tells them to enable local/session storage.
 */

const isLocalStorageAvailable = (): boolean => {
  const test = '__local_test_key';
  try {
    localStorage.setItem(test, test);
    localStorage.removeItem(test);
    return true;
  } catch (e) {
    return false;
  }
};

const isSessionStorageAvailable = (): boolean => {
  const test = '__session_test_key';
  try {
    sessionStorage.setItem(test, test);
    sessionStorage.removeItem(test);
    return true;
  } catch (e) {
    return false;
  }
};

// Detection for required features
if (!isLocalStorageAvailable() || !isSessionStorageAvailable()) {
  window.location.href = `/browser-storage-required?redirect_to=${window.location.pathname}`;
}
